import React from 'react'
import logoFooter from '../../images/loogoo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTelegram } from '@fortawesome/free-brands-svg-icons'
import "./Footer.css"
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <footer id='footer' >
            <div className="container pt-4 footer">
                <div className="row pb-4 footer-top">
                    <div className="col-sm-6 text-center text-sm-start mb-3 mb-sm-0">
                        <Link to="/"> <img src={logoFooter} alt="?" color="white" width="200px" /></Link>
                    </div>
              
                </div>
                <div className="border-bot"></div>
                <div className="row">
                    <div className="col-md-3 col-6 text-center text-md-start">
                        <h5 className="text-white mb-4 mt-4">Услуги</h5>
                        <ul>
                            <li><Link to="/cont">Услуги</Link></li>
                            <li><Link to="/services">Заводы</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-3 col-6 text-center text-md-start">
                        <h5 className="text-white mb-4 mt-4">Информация</h5>
                        <ul>
                            <li><Link to="/inform">Информация</Link></li>
                            <li><Link to="/cont">Вопросы и ответы</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-3 col-6 text-center text-md-start">
                        <h5 className="text-white mb-4 mt-4">Компания</h5>
                        <ul>
                            <li><Link to="/docs">О компании</Link></li>
                            <li><Link to="/docs">Новости</Link></li>

                        </ul>
                    </div>
                    <div className="col-md-3 col-6 text-center text-md-start">
                        <h5 className="text-white mb-4 mt-4">Контакты</h5>
                        <ul>
                            <li><Link to="#">+998 93 187 36 02</Link></li>
                            <li><a href="mailto:ekvatorialjeans@email.com" className='email'>ekvatorialjeans@email.com</a></li>
                        </ul>
                    </div>
                    <div className="col-12 text-md-end text-center text-white mt-4">
                        <p>K_M_K  © 2024</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer