import React, { useState } from 'react'
import "./Header.css"
import logo from "../../images/loogoo.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone, faSortDown, faSortUp, faTimes, faGlobe, faSearch } from '@fortawesome/free-solid-svg-icons'
import Dropdown from 'react-bootstrap/Dropdown'
import { DropdownButton } from 'react-bootstrap'
import ru from '../../images/rus.png'
import en from '../../images/eng.png'
import uz from '../../images/uz.png'
import Navbar from '../Navbar/Navbar'
import { Link } from 'react-router-dom'
import '../Footer/Footer'
import { DropdownItem } from 'reactstrap'
function Header() {
    const [open, setOpen] = useState(false)
    const [drop, setDrop] = useState(false)
    const [drop2, setDrop2] = useState(false)
    const [value, setValue] = useState("Ru")
    const handleSelect = (e) => {
        setValue(e)
    }
    const [valute, setValute] = useState('USD 12 500')
    const selectValute = (v) => {
        setValute(v)
    }
    const key = DropdownItem.eventKey;
    return (
        <header>
            <nav>
                <Link to="/"> <img src={logo} alt="?" width="200px" /></Link>
                <ul className="links">
                    <li><Link to="/" className="toplink">Дом</Link></li>
                    <li>
                        <Link className="toplink" to="/services">Заводы</Link>

                    </li>
                    <li>
                        <Link className="toplink" onClick={() => setDrop2(true)} to="#">Информация</Link>
                        {drop2 ? <div className="drop-hidden position-absolute">
                            <div className="drop-body">
                                <FontAwesomeIcon onClick={() => setDrop2(false) || setDrop(false)} icon={faTimes} />
                                <div className="container-fluid px-5">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <ul>
                                                <li><Link to="#">+998 93 187 36 02</Link></li>
                                                <li><a href="mailto:ekvatorialjeans@email.com">ekvatorialjeans@email.com</a></li>                                            </ul>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <h4>Общая Информация</h4>
                                            <ul>
                                                <li><Link to="/inform">История компании</Link></li>
                                                <li><Link to="/inform">Ассортимент</Link></li>
                                               
                                            </ul>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <h4>Стандарты</h4>
                                            <ul>
                                                <li><Link to='/docs'>Документы</Link></li>
                                                <li><Link to='/docs'>Материалы и ткани</Link></li>
                    
                                            </ul>
                                        </div>
                                       
                                        <div className="col-lg-3 col-md-6">
                                            <h4>О компании</h4>
                                            <ul>
            
                                                <li><Link to="/cont">Пресс-Центр</Link></li>
                                                <li><Link to="/cont">Связаться с нами</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : null}</li>
                    <li><Link className="toplink" to='/cont'>Cвязаться с нами</Link></li>
                </ul>

                <ul className="top-right d-flex align-items-center">
                    <li>
                        <div onClick={() => setOpen(true)} className="search">
                            <FontAwesomeIcon icon={faSearch} /> Поиск
                        </div>
                        <div className="hidden-input">
                            {open ? <form>
                                <input type="text" placeholder="Поиск по сайту" />
                                <button type="submit">
                                    <FontAwesomeIcon icon={faSearch} />
                                </button>
                                <FontAwesomeIcon onClick={() => setOpen(false)} icon={faTimes} />
                            </form> : null}
                        </div>
                    </li>
                    {/* <li className="middle d-flex align-items-center ">
                        <DropdownButton
                            title={value}
                            onSelect={handleSelect}
                            id=''
                        >
                            <Dropdown.Item eventKey="Ru" id='ru'><img src={ru} width="20px" alt="?"  />Ru</Dropdown.Item>
                            <Dropdown.Item eventKey="En" id='en'><img src={en} width="20px" alt="?"  /> En</Dropdown.Item>
                            <Dropdown.Item eventKey="Uz" id='uz'><img src={uz} width="20px" alt="?"  /> Uz</Dropdown.Item>
                        </DropdownButton>
                    </li> */}
                </ul>
            </nav >
            <Navbar />
        </header >
    )
}

export default Header
