import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';


import './Minitab.css'


function Minitab() {
     const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_2rtevyg', 'template_ivapbhk', form.current, {
        publicKey: 'sf_DovjZlAZWzc6Ra',
      })
      .then(
        () => {
              document.getElementById('text').textContent = "Done!!!";
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
    
    return (
        <>
            <div className="container-fluid widgetmini">
              <Form ref={form} onSubmit={sendEmail}>
      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>имя</Form.Label>
          <Form.Control
            required
            type="text"
            name='user_name'
          />
         
        </Form.Group>
       
        
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustomUsername">
          <Form.Label>е-мейл</Form.Label>
          <InputGroup hasValidation>
            <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
            <Form.Control
              type="email"
              aria-describedby="inputGroupPrepend"
              required
              name='user_email'
            />
           
          </InputGroup>
        </Form.Group>
      </Row>
      <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>сообщение</Form.Label>
          <Form.Control
            required
                type="text"
                name='message'
          />
         
        </Form.Group> 
                    <Button value="Send" type="submit" className='mt-5 bg-dark'>Отправить</Button>
                    <Button id='text'></Button>
    </Form> 
            </div>
        </>
    )
}

export default Minitab