import React from 'react'
import "../FontAwesomeIcon/index"
import './Way.css'
import first from '../../images/1st.jpg'
import second from '../../images/2nd.jpg'
import third from '../../images/3rd.jpg'
import four from '../../images/4t.jpg'
import five from '../../images/5t.jpg'
import six from '../../images/6t.jpg'
import { Link } from 'react-router-dom'


const Way = () => {
const animatedEls = document.querySelectorAll("[data-animation]");

const observer = new IntersectionObserver((entries) => {
	entries.forEach((entry) => {
		const animation = entry.target.getAttribute("data-animation");

		if (entry.isIntersecting) {
			entry.target.classList.add("animated", `${animation}`);
		} else {
			entry.target.classList.remove("animated", `${animation}`);
		}
	});
});

animatedEls.forEach((el) => observer.observe(el));

    return (
        <>
            <div className="container way">
              
           <section class="wrapper-full tab-container effectTab-header" id="style_4">
      <div class="wrapper tab-item">
        <div class="custom-row row">
          <div class="wrapper" align="center">
            <h1 class="ti title galare">галерея</h1> 
          </div>
          <div class="column-12 tab column-xs-12">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-12 box-tab" >
                <div class="effect effect-four">
                  <img src={first} class="img-fluid" />
                  <div class="tab-text">
                    <p>Вся одежда подшивается в строгом соответствии с техническими условиями, предоставленными заказчиком.</p>
                        
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-12 box-tab">
                <div class="effect effect-four">
                  <img src={second} class="img-fluid" />
                  <div class="tab-text">
                    <p>Вся одежда подшивается в строгом соответствии с техническими условиями, предоставленными заказчиком.</p>
                    <div class="icons-block"> <a href="#" class="social-icon-1"><i class="fa fa-facebook-official"></i></a> <a href="#" class="social-icon-2"><i class="fa fa-twitter-square"></i></a> <a href="#" class="social-icon-3"><i class="fa fa-youtube-square"></i></a> </div>
                  </div>
                </div>
                                    </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-12 box-tab">
                <div class="effect effect-four">
                  <img src={third} class="img-fluid" />
                  <div class="tab-text">
                    <p>Вся одежда подшивается в строгом соответствии с техническими условиями, предоставленными заказчиком.</p>
                    </div>
                </div>
                                    </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-12 box-tab">
                <div class="effect effect-four">
                  <img src={four} class="img-fluid" />
                  <div class="tab-text">
                    <p>Вся одежда подшивается в строгом соответствии с техническими условиями, предоставленными заказчиком.</p>
                   </div>
                </div>
                                    </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-12 box-tab">
                <div class="effect effect-four">
                  <img src={five} class="img-fluid" />
                  <div class="tab-text">
                    <p>Вся одежда подшивается в строгом соответствии с техническими условиями, предоставленными заказчиком.</p>
                    </div>
                </div>
                                    </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-12 box-tab">
                <div class="effect effect-four">
                  <img src={six} class="img-fluid" />
                  <div class="tab-text">
                    <p>Вся одежда подшивается в строгом соответствии с техническими условиями, предоставленными заказчиком.</p>
                   </div>
                </div>
                    </div> 
                   <div class="col-xl-12 col-lg-12 col-md-12 col-12 box-tab mt-5">
               <Link to='/phts' className="bolshe">больше фотографий</Link>
                    </div>  
            </div>
          </div>
        </div>
      </div>
    </section>
            </div>
        </>
    )
}

export default Way
