import React from 'react'
import "../FontAwesomeIcon/index"
import './Galari.css'
import first from '../../images/1st.jpg'
import second from '../../images/2nd.jpg'
import third from '../../images/3rd.jpg'
import four from '../../images/4t.jpg'
import five from '../../images/5t.jpg'
import six from '../../images/6t.jpg'
import video1 from '../../images/video2.mp4'
import video2 from '../../images/video1.mp4'
import t7 from '../../images/7t.jpg'
import t8 from '../../images/8t.jpg'
import t9 from '../../images/9t.jpg'
import t10 from '../../images/10t.jpg'
import t11 from '../../images/11t.jpg'
import t12 from '../../images/12t.jpg'
import t13 from '../../images/13t.jpg'
import t14 from '../../images/14t.jpg'
import t15 from '../../images/15t.jpg'
const Galari = () => {
const animatedEls = document.querySelectorAll("[data-animation]");

const observer = new IntersectionObserver((entries) => {
	entries.forEach((entry) => {
		const animation = entry.target.getAttribute("data-animation");

		if (entry.isIntersecting) {
			entry.target.classList.add("animated", `${animation}`);
		} else {
			entry.target.classList.remove("animated", `${animation}`);
		}
	});
});

animatedEls.forEach((el) => observer.observe(el));

    return (
        <>
            <div className="container way">
              
           <section class="wrapper-full tab-container effectTab-header" id="style_4">
      <div class="wrapper tab-item">
        <div class="custom-row row">
          <div class="wrapper" align="center">
            <h1 class="ti title galare">галерея</h1> 
          </div>
          <div class="column-12 tab column-xs-12">
            <div class="row">
              <div class="col-xl-4 col-lg-6 col-md-6 col-12 box-tab" >
                <div class="effect effect-four">
                  <img src={first} class="img-fluid" />
                  <div class="tab-text">
                    <p>Вся одежда подшивается в строгом соответствии с техническими условиями, предоставленными заказчиком.</p>
                        
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 col-md-6 col-12 box-tab">
                <div class="effect effect-four">
                  <img src={t11} class="img-fluid" />
                  <div class="tab-text">
                    <p>Вся одежда подшивается в строгом соответствии с техническими условиями, предоставленными заказчиком.</p>
                    <div class="icons-block"> <a href="#" class="social-icon-1"><i class="fa fa-facebook-official"></i></a> <a href="#" class="social-icon-2"><i class="fa fa-twitter-square"></i></a> <a href="#" class="social-icon-3"><i class="fa fa-youtube-square"></i></a> </div>
                  </div>
                </div>
                                    </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-12 box-tab">
                <div class="effect effect-four">
                  <img src={t8} class="img-fluid" />
                  <div class="tab-text">
                    <p>Вся одежда подшивается в строгом соответствии с техническими условиями, предоставленными заказчиком.</p>
                    </div>
                </div>
                                    </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-12 box-tab">
                <div class="effect effect-four">
                  <img src={four} class="img-fluid" />
                  <div class="tab-text">
                    <p>Вся одежда подшивается в строгом соответствии с техническими условиями, предоставленными заказчиком.</p>
                   </div>
                </div>
                                    </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-12 box-tab">
                <div class="effect effect-four">
                  <img src={five} class="img-fluid" />
                  <div class="tab-text">
                    <p>Вся одежда подшивается в строгом соответствии с техническими условиями, предоставленными заказчиком.</p>
                    </div>
                </div>
                                    </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-12 box-tab">
                <div class="effect effect-four">
                  <img src={six} class="img-fluid" />
                  <div class="tab-text">
                    <p>Вся одежда подшивается в строгом соответствии с техническими условиями, предоставленными заказчиком.</p>
                   </div>
                </div>
                    </div> 
                   <div class="col-xl-8 col-lg-6 col-md-6 col-12 box-tab">
                    <video controls className='video1 pt-0'>
  
              <source src={video1} type="video/mp4"/>
                      </video> </div> 
                   <div class="col-xl-4 col-lg-6 col-md-6 col-12 pt-0" style={{height:'420px',backgroundColor:'pink'}}>
               
                  <img src={second} class="img-fluid" />
                  <div class="tab-text">
                    <p>Вся одежда подшивается в строгом соответствии с техническими условиями, предоставленными заказчиком.</p>
                   
                </div>
                    </div>  
                    
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12 box-tab">
                <div class="effect effect-four">
                  <img src={third} class="img-fluid" />
                  <div class="tab-text">
                    <p>Вся одежда подшивается в строгом соответствии с техническими условиями, предоставленными заказчиком.</p>
                   </div>
                </div>
                    </div> 
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12 box-tab">
                <div class="effect effect-four">
                  <img src={t9} class="img-fluid" />
                  <div class="tab-text">
                    <p>Вся одежда подшивается в строгом соответствии с техническими условиями, предоставленными заказчиком.</p>
                   </div>
                </div>
                    </div>
                     <div class="col-xl-4 col-lg-6 col-md-6 col-12 box-tab">
                <div class="effect effect-four">
                  <img src={t10} class="img-fluid" />
                  <div class="tab-text">
                    <p>Вся одежда подшивается в строгом соответствии с техническими условиями, предоставленными заказчиком.</p>
                   </div>
                </div>
                    </div> 
                    <div class="col-xl-3 col-lg-6 col-md-6 col-12 box-tab">
                <div class="effect effect-four">
                  <img src={t7} class="img-fluid" />
                  <div class="tab-text">
                    <p>Вся одежда подшивается в строгом соответствии с техническими условиями, предоставленными заказчиком.</p>
                   </div>
                </div>
                    </div>
                        <div class="col-xl-3 col-lg-6 col-md-6 col-12 box-tab">
                <div class="effect effect-four">
                  <img src={t12} class="img-fluid" />
                  <div class="tab-text">
                    <p>Вся одежда подшивается в строгом соответствии с техническими условиями, предоставленными заказчиком.</p>
                   </div>
                </div>
                    </div> 
                     <div class="col-xl-3 col-lg-6 col-md-6 col-12 box-tab">
                <div class="effect effect-four">
                  <img src={t13} class="img-fluid" />
                  <div class="tab-text">
                    <p>Вся одежда подшивается в строгом соответствии с техническими условиями, предоставленными заказчиком.</p>
                   </div>
                </div>
                    </div>
                        <div class="col-xl-3 col-lg-6 col-md-6 col-12 box-tab">
                <div class="effect effect-four">
                  <img src={four} class="img-fluid" />
                  <div class="tab-text">
                    <p>Вся одежда подшивается в строгом соответствии с техническими условиями, предоставленными заказчиком.</p>
                   </div>
                </div>
                    </div> 
                     <div class="col-xl-4 col-lg-6 col-md-6 col-12 pt-0" style={{height:'420px',backgroundColor:'green'}}>
               
                  <img src={first} class="img-fluid" />
                  <div class="tab-text">
                    <p>Вся одежда подшивается в строгом соответствии с техническими условиями, предоставленными заказчиком.</p>
                   
                </div>
                    </div>  
                    <div class="col-xl-8 col-lg-6 col-md-6 col-12 box-tab">
                    <video controls className='video1 pt-0'>
  
              <source src={video2} type="video/mp4"/>
                      </video> </div> 
                  <div class="col-xl-4 col-lg-6 col-md-6 col-12 box-tab" >
                <div class="effect effect-four">
                  <img src={t14} class="img-fluid" />
                  <div class="tab-text">
                    <p>Вся одежда подшивается в строгом соответствии с техническими условиями, предоставленными заказчиком.</p>
                        
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 col-md-6 col-12 box-tab">
                <div class="effect effect-four">
                  <img src={second} class="img-fluid" />
                  <div class="tab-text">
                    <p>Вся одежда подшивается в строгом соответствии с техническими условиями, предоставленными заказчиком.</p>
                    <div class="icons-block"> <a href="#" class="social-icon-1"><i class="fa fa-facebook-official"></i></a> <a href="#" class="social-icon-2"><i class="fa fa-twitter-square"></i></a> <a href="#" class="social-icon-3"><i class="fa fa-youtube-square"></i></a> </div>
                  </div>
                </div>
                                    </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-12 box-tab">
                <div class="effect effect-four">
                  <img src={t15} class="img-fluid" />
                  <div class="tab-text">
                    <p>Вся одежда подшивается в строгом соответствии с техническими условиями, предоставленными заказчиком.</p>
                    </div>
                </div>
                                    </div> 
            </div>
          </div>
        </div>
      </div>
    </section>
            </div>
        </>
    )
}

export default Galari
