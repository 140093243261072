import React from 'react'
import services1 from '../../images/services-1.webp'
import services2 from '../../images/services-2.webp'
import services3 from '../../images/services-3.webp'
import services4 from '../../images/services-4.webp'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./Services.css"

function Services() {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
   const animatedEls = document.querySelectorAll("[data-animation]");

const observer = new IntersectionObserver((entries) => {
	entries.forEach((entry) => {
		const animation = entry.target.getAttribute("data-animation");

		if (entry.isIntersecting) {
			entry.target.classList.add("animated", `${animation}`);
		} else {
			entry.target.classList.remove("animated", `${animation}`);
		}
	});
});

animatedEls.forEach((el) => observer.observe(el));

    return (
        <div id='services' className="container services pb-5">
            <h2 className="ms-4 pt-5 fw-normal glitch">Заводы</h2>
            <div className="row" >
                <div className="col-lg-6 d-flex justify-content-center hover1 mt-4 ">
                    <div className="col-11 d-flex shadow " style={{ background: "#4b89bf" }}>
                        <div className="d-flex flex-column justify-content-between">
                            <div className="text-white">
                                <h5>ВОБКЕНТ EKVATORIAL CHODAK</h5>
                                <p>Узбекистан, Бухарская область,

Район Вобкент, улица Г.Гуляма, 54/а.</p>
                            </div>
                            <Button variant="primary" onClick={handleShow}>
      Узнать больше 
      </Button>  </div>
                        <img src={services1} alt="?" />
                    </div>
                </div>
                <div className="col-lg-6 d-flex justify-content-center hover1 mt-4">
                    <div className="col-11 d-flex shadow" style={{ background: "#4b89bf" }}>
                        <div className="d-flex flex-column justify-content-between">
                            <div className="text-white">
                                <h5>ПАП EKVATORIAL CHODAK</h5>
                                <p>Узбекистан, Наманганская обль. Папский район. </p>
                            </div>
                            <Button variant="primary" onClick={handleShow}>
      Узнать больше 
      </Button>
                        </div>
                        <img src={services2} alt="?" />
                    </div>
                </div>
                <div className="col-lg-6 d-flex justify-content-center hover1 mt-4">
                    <div className="col-11 d-flex shadow justify-content-between pe-0" style={{ background: "#4b89bf" }}>
                        <div className="d-flex flex-column justify-content-between">
                            <div className="text-white">
                                <h5>БУХАРА EKVATORIAL CHODAK</h5>
                                <p>Узбекистан, Бухарская обль. г. Бухара.</p>
                            </div>
                         <Button variant="primary" onClick={handleShow}>
      Узнать больше 
      </Button>
                        </div>
                        <img className="img-out" src={services3} alt="?" />
                    </div>
                </div>
                <div className="col-lg-6 d-flex justify-content-center hover1 mt-4">
                    <div className="col-11 d-flex shadow justify-content-between pe-0" style={{ background: "#4b89bf" }}>
                        <div className="d-flex flex-column justify-content-between">
                            <div className="text-white">
                                <h5>ШАФИРКОН EKVATORIAL CHODAK</h5>
                                <p>Узбекистан, Бухарская область,</p>
                            </div>
                       <Button variant="primary" onClick={handleShow}>
      Узнать больше 
      </Button>
                        </div>
                        <img className="img-out" src={services4} alt="?" />
                    </div>
                </div>
            </div>


          <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>ВОБКЕНТ EKVATORIAL CHODAK</Modal.Title>
        </Modal.Header>
        <Modal.Body>Фабрика находится Узбекистан, Бухарская область,

Район Вобкент, улица Г.Гуляма, 54/а. Общая площадь 34,1 тыс. кв.м, Производственная площадь 17,8 тыс.кв.м

Фабрика специализируется на производстве джинсовых изделий. В настоящее время фабрика выпускает различные джинсовых брюки, рубашки, куртка зимняя, костюм, куртки и брюки, китель и брюки полушерстяные, куртки облегченные полушерстяные, брюки форменные полушерстяные, юбка женская, комбинезоны, пальто и полупальто, разгрузочный жилет. и др. Фабрика оснащена швейными машинами марки «Brother» Япония, «Juki» Япония, «Pegasus» Япония,«Kansai» Япония, «Sipami» Италия 1 шт.. Основными рынками сбыта являются такие страны, как Россия, Украина, Кыргызстан и Казахстан.</Modal.Body>
           <img src={services1} alt="?" />
                <div className="row">
                                         <div className="col-lg-1 col-md-6 nmadr">
                        
                    </div>
                    <div className="col-lg-3 col-md-6 nmadr">
                        <h3>190</h3>
                        <h6>Страны-экспортеры</h6>
                    </div>
                                        <div className="col-lg-3 col-md-6 nmadr">
                                         <h3>500+</h3>
                        <h6>Машины</h6>
                                        </div>
                                        <div className="col-lg-3 col-md-6 nmadr">
                                          <h3>2000+</h3>
                        <h6>Сотрудники</h6>
                                        </div>
                                 <div className="col-lg-1 col-md-6 nmadr">
                      
                    </div>
                                    </div>
                <Modal.Header >
          <Modal.Title>ПАП EKVATORIAL CHODAK</Modal.Title>
        </Modal.Header>
        <Modal.Body>Фабрика находится Узбекистан, Наманганская обль. Папский район. Общая площадь 81.0 тыс. кв.м, Производственная площадь 29 тыс.кв.м

Фабрика специализируется на производстве джинсовых изделий. 10 производственных линий для различных моделей ДЕНИМ, 4 производственных линий для очень сложных моделей, Фабрика оснащена швейными машинами марки «Brother» Япония, «Juki» Япония, «Pegasus» Япония,«Kansai» Япония, «Sipami» Италия. Основными рынками сбыта являются такие страны, как Россия, Украина, Кыргызстан и Казахстан.</Modal.Body>
           <img src={services2} alt="?" />
                <div className="row">
                                         <div className="col-lg-1 col-md-6 nmadr">
                        
                    </div>
                    <div className="col-lg-3 col-md-6 nmadr">
                        <h3>5</h3>
                        <h6>Страны-экспортеры</h6>
                    </div>
                                        <div className="col-lg-3 col-md-6 nmadr">
                                         <h3>700+</h3>
                        <h6>Машины</h6>
                                        </div>
                                        <div className="col-lg-3 col-md-6 nmadr">
                                          <h3>1000+</h3>
                        <h6>Сотрудники</h6>
                                        </div>
                                 <div className="col-lg-1 col-md-6 nmadr">
                      
                    </div>
                </div>
                 <Modal.Header >
          <Modal.Title>БУХАРА EKVATORIAL CHODAK</Modal.Title>
        </Modal.Header>
        <Modal.Body>

Фабрика находится Узбекистан, Бухарская обль. г. Бухара. Общая площадь 30 тыс. кв.м, Производственная площадь 24.5 тыс.кв.м. Действующая фабрика в ввод в эксп. 2021 г

Фабрика специализируется на производстве джинсовых изделий. 8 производственных линий для различных моделей ДЕНИМ,

Фабрика оснащена швейными машинами марки «Brother» Япония, «Juki» Япония, «Pegasus» Япония,«Kansai» Япония, «Sipami» Италия. Красильная и сушильная оборудования «Tolkar» Турция, «Metod» Турция.

Раскройные оборудование и программный продукт по автоматизации раскладки лекал «Gemini» Польша, «RIMOLDI» Италия.

Технологии по влажное тепловое обработке «Malkan» Турция, «Macpi» Италия.

Вышивальная машина- «TAJIMA» Япония

Основными рынками сбыта являются такие страны, как Россия, Украина, Кыргызстан и Казахстан.</Modal.Body>
           <img src={services3} alt="?" />
                <div className="row">
                                         <div className="col-lg-1 col-md-6 nmadr">
                        
                    </div>
                    <div className="col-lg-3 col-md-6 nmadr">
                        <h3>10</h3>
                        <h6>Страны-экспортеры</h6>
                    </div>
                                        <div className="col-lg-3 col-md-6 nmadr">
                                         <h3>900+</h3>
                        <h6>Машины</h6>
                                        </div>
                                        <div className="col-lg-3 col-md-6 nmadr">
                                          <h3>1300+</h3>
                        <h6>Сотрудники</h6>
                                        </div>
                                 <div className="col-lg-1 col-md-6 nmadr">
                      
                    </div>
                </div>
                 <Modal.Header >
          <Modal.Title>ШАФИРКОН EKVATORIAL CHODAK</Modal.Title>
        </Modal.Header>
        <Modal.Body>

Фабрика находится Узбекистан, Бухарская область,

Район Шафиркон.Общая площадь 5 тыс. кв.м, Производственная площадь 3.5 тыс.кв.м. Действующая фабрика в ввод в эксп. 1993 г.

Фабрика оснащена швейными машинами марки «Brother» Япония, «Juki» Япония, «Pegasus» Япония,«Kansai» Япония, «Sipami» Италия. Красильная и сушильная оборудования «Tolkar» Турция, «Metod» Турция.

</Modal.Body>
           <img src={services4} alt="?" />
                <div className="row">
                                         <div className="col-lg-1 col-md-6 nmadr">
                        
                    </div>
                    <div className="col-lg-3 col-md-6 nmadr">
                        <h3>6</h3>
                        <h6>Страны-экспортеры</h6>
                    </div>
                                        <div className="col-lg-3 col-md-6 nmadr">
                                         <h3>180+</h3>
                        <h6>Машины</h6>
                                        </div>
                                        <div className="col-lg-3 col-md-6 nmadr">
                                          <h3>250+</h3>
                        <h6>Сотрудники</h6>
                                        </div>
                                 <div className="col-lg-1 col-md-6 nmadr">
                      
                    </div>
                </div>
                
                <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
           закрыть 
          </Button>
          <Button variant="primary" onClick={handleClose}>
           Сохранить изменения
          </Button>
        </Modal.Footer>
      </Modal> 
        </div>
    )
}

export default Services