import React from "react";
import GoogleMapReact from "google-map-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faMailchimp, faTelegram } from '@fortawesome/free-brands-svg-icons'
import './WorldMap.css'
import { faMailBulk, faPooStorm, faVoicemail } from "@fortawesome/free-solid-svg-icons";
function WorldMap() {
    return (
        
        <div id="root" className="container ">
            <div class="wrapper py-5" align="center">
            <h1 class="ti title fw-normal glitch">Адрес офиса</h1> 
            </div>
            <div className="row">
               
            <div className="col-xl-6 col-lg-6 col-sm-12 col-12" style={{ overflow: 'hidden', position: 'relative' }}>
              <a href="https://yandex.uz/maps/10330/bukhara/?utm_medium=mapframe&utm_source=maps" style={{color:'#eee', fontSize:"12px",position:'absolute'}}>Bukhara</a>
              <a href="https://yandex.uz/maps/10330/bukhara/?ll=64.396953%2C39.766534&mode=search&sll=64.393664%2C39.764784&text=39.764784%2C64.393664&utm_campaign=desktop&utm_medium=mapframe&utm_source=maps&z=16.13" style={{color:'#eee', fontSize:"12px",position:'absolute'}}>Bukhara — Yandex Maps</a>
              <iframe src="https://yandex.uz/map-widget/v1/?ll=64.396953%2C39.766534&mode=search&sll=64.393664%2C39.764784&text=39.764784%2C64.393664&utm_campaign=desktop&utm_medium=search&utm_source=maps&z=16.13" style={{width:'100%',height:'500px'}}>
            </iframe>
                </div>
                      
              
                <div className="col-xl-6 col-lg-6 col-sm-12 col-12 text01">
                    <h3 style={{color:'#a6a3a3'}}>
                      ВОБКЕНТ EKVATORIAL CHODAK   
                    </h3>
                    <h5>Узбекистан, Бухарская область, Район Вобкент, улица Г.Гуляма, 54/а.</h5>
                    <h3 style={{color:'#a6a3a3'}}>
                     ПАП EKVATORIAL CHODAK
                    </h3>
                    <h5>Узбекистан, Наманганская обль. Папский район.</h5>
                    <h3 style={{ color: '#a6a3a3' }}>
                      БУХАРА EKVATORIAL CHODAK
                    </h3>
                    <h5>Узбекистан, Бухарская обль. г. Бухара.</h5>
                    <h3 style={{ color: '#a6a3a3' }}>
                      ШАФИРКОН EKVATORIAL CHODAK 
                    </h3>
                    <h5>Узбекистан, Бухарская область, Район Шафиркон.</h5>
                        <a href="https://www.instagram.com/ekvatorial_jeans">
                            <FontAwesomeIcon icon={faInstagram} className="icons insta"/>
                        </a>
                        <a className="" href="https://www.facebook.com/ekvatorial.chodak">
                            <FontAwesomeIcon icon={faFacebook} className="icons"/>
                        </a>
                        <a className="ms-2" href="https://t.me/ekvatorialteks">
                            <FontAwesomeIcon icon={faTelegram} className="icons"/>
                    </a>
                    <a className="ms-2" href="mailto:ekvatorialjeans@email.com">
                            <FontAwesomeIcon icon={faMailBulk} className="icons"/>
                        </a>
                    </div>
        </div>
          </div>
    )
}

export default WorldMap;