import React from 'react'
import video1 from '../../images/video1.mp4'
import video2 from '../../images/video2.mp4'
import './Docs.css'
 

function Docs() {

    return (
        <div id='docs' className="container news py-0">
        <div class="row">
                <div className='col-xl-12 col-lg-12 col-sm-12 col-12'>
       <video controls className='video1 pt-5'>
  
              <source src={video2} type="video/mp4"/>
</video>
                </div> 
                <div className='col-xl-12 col-lg-12 col-sm-12 col-12'>
                    <h4 className=' fw-normal glitch'>Материалы и ткани</h4>
                    <p className='long-text hero-heading'>
                     Используемые при пошиве форменной и ведомственной верхней одежды материалы проходят серьезные многоуровневые испытания специализированными лабораториями и соответствуют жестким требованиям стандарта.
            </p>
               <div className='col-xl-12 col-lg-12 col-sm-12 col-12'>
                    <h4 className=' fw-normal glitch'>История</h4>
                    <p className='long-text hero-heading'>
                        История компании, известна на данный момент рынку под именем «Evatorial», насчитывает уже более 23 лет.
                        Компания крупнейшее предприятие швейной промышленности Узбекистана, специализируется на выпуске джинсовых изделии и форменного обмундирования для всех отраслей.
                        Компания «EKVATORIAL GROUP» выпускает продукции в строгом соответствии с техническими условиями, предоставленными заказчиком: В зависимости от вида выполняемой операции при пошиве изделий используются различные виды швейного оборудования. Экспериментальный цех оснащен системой автоматизированного проектирования. Кроме того используется автоматизированного система вывода лекал, что позволяет обеспечивать все швейные фабрики оригиналами лекал и обеспечивать высокое и равномерное качество готовой продукции. Во все производственные процессы освоены новейшие технологии, и ежегодно расширяется с учетом динамичного развития компании. 
                      </p>
                </div> 
            
             <div className='col-xl-12 col-lg-12 col-sm-12 col-12'>
                    <h4 className=' fw-normal glitch'>Деятельность</h4>
                    <p className='long-text hero-heading'>
                      Компания «EKVATORIAL GROUP» выпускает продукции в строгом соответствии с техническими условиями, предоставленными заказчиком: В зависимости от вида выполняемой операции при пошиве изделий используются различные виды швейного оборудования. Экспериментальный цех оснащен системой автоматизированного проектирования. Кроме того используется автоматизированного система вывода лекал, что позволяет обеспечивать все швейные фабрики оригиналами лекал и обеспечивать высокое и равномерное качество готовой продукции. Во все производственные процессы освоены новейшие технологии, и ежегодно расширяется с учетом динамичного развития компании. 
                      </p>
          </div>  
            
          </div>  
            <div className='col-xl-12 col-lg-12 col-sm-12 col-12'>
                    <h4 className=' fw-normal glitch'>Стандарты</h4>
                    <p className='long-text hero-heading'>
                     Вся одежда, производимая Компанией, отвечает требованиям узбекских и зарубежных стандартов, а также имеет сертификаты и протоколы испытании, выданные международными лабораториями.

Вся одежда подшивается в строгом соответствии с техническими условиями, предоставленными заказчиком.
            </p>
                </div>  
<div className='col-xl-12 col-lg-12 col-sm-12 col-12'>
       <video controls className='video2 pb-5'>
  
              <source src={video1} type="video/mp4"/>
</video>
                </div> 
                  

</div>
   
        </div>
    )
}

export default Docs
