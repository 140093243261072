
import "./Main.css"
import MainCarousel from '../../Components/MainCarousel/MainCarousel'
import Tabs from "../../Components/Tabs/Tabs"
import Way from '../../Components/Way/Way'
import News from '../../Components/News/News'
import Services from '../../Components/Services/Services'
import Minitab from '../../Components/Minitab/Minitab'
import WorldMap from '../../Components/WorldMap/WorldMap'


function Main() {
   
    return (
        <div>
            <MainCarousel />
            <div className="section-2">
            <Minitab/> 
            <Tabs />
            <Services/>
        </div>
        <div className="section-3">
            <News />
        </div>
            <div className="section-5">
            <Way /> 
        </div>
        <div className="section-4">
            <WorldMap />
        </div>
        
    </div >
)
}

export default Main
