import 'bootstrap/dist/css/bootstrap.min.css';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Main from './pages/Main/Main';
import Docs from './Components/Docs/Docs';
import Galari from './Components/Galari/Galari';
import Contact from './Contact/Contact';
import News from './Components/News/News';
import Services from './Components/Services/Services';

function App() {
  return (
      <Router>
        <Header />

        <Switch>
          <Route exact path="/">
            <Main />
          </Route>

 <Route exact path="/docs">
            <Docs />
        </Route>
        <Route exact path="/phts">
            <Galari />
        </Route>
        <Route exact path="/cont">
            <Contact />
        </Route>
        <Route exact path="/inform">
            <News />
        </Route>
        <Route exact path="/services">
            <Services />
        </Route>
        </Switch>

        <Footer />
      </Router>
  );
}

export default App;
