import React, { Component } from "react";
import './MainCarousel.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bg1 from "../../images/1.jpg";
import bg2 from "../../images/200.jpg";

export default class MainCarousel extends Component {
    render() {
        const settings = {
            autoplay: true,
            autoplaySpeed: 3000,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
        };
        return (
            <div id="carusel" className="container-fluid">
                <Slider {...settings} className="abs">
                    <div className="bg-slide1">
                        <img src={bg1} width="100%" height='750px' alt="?" />
                        
                    </div>
                    <div className="bg-slide1">
                        <img src={bg2} width="100%" height='750px' alt="?" />
                    </div>
               </Slider>
            </div>
        );
    }
}