import React, { useRef } from 'react'
import { Link } from 'react-router-dom';
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import{ faBars, faSearch } from '@fortawesome/free-solid-svg-icons'
import { Nav, NavItem} from 'react-bootstrap';
import logo from "../../images/loogoo.png"
import { Accordion } from 'react-bootstrap'
import './Navbar.css'

function Navbar() {
    const ref = useRef();

    const [sidebar, setSidebar] = useState(false)

    const handleClick = () => {
        setSidebar((prev) => !prev);
    };

    const handleClickAway = () => {
        setSidebar(false);
    };


    return(
        <>
          
            
                    <div className='navbar'>
                        <Link to='#' className='menu-bars'>
                           <FontAwesomeIcon icon={faBars} onClick={handleClick}> </FontAwesomeIcon>
                        </Link>
                    </div>
                    <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                        <div ref={ref} className='nav-menu-items'>
                            <form className="search_sidebar">
                                <input type="text" placeholder="Поиск по сайту" />
                                <button>
                                    <FontAwesomeIcon icon={faSearch} />
                                </button>
                            </form>
                            <div className="px-3">
                       
                             <li><a  href="#carusel" className="toplink">Дом</a></li>
                    <li>
                        <a className="toplink" href="#services">Заводы</a>

                    </li>
                        <li><a className="toplink" href="#footer">Call Center</a></li>
                          <li><Link to="#">+998 93 187 36 02</Link></li>
                                                <li><a href="mailto:ekvatorialjeans@email.com">ekvatorialjeans@email.com</a></li>                                          
                            </div>
                        </div>
                    </nav>
           
           
        </>
    )
}

export default Navbar