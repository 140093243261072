import React from 'react'
import video1 from '../../images/video1.mp4'
import video2 from '../../images/video2.mp4'
import './News.css'
 import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import first from '../../images/1st.jpg'
import second from '../../images/2nd.jpg'
import third from '../../images/3rd.jpg'
import four from '../../images/4t.jpg'
import five from '../../images/5t.jpg'
import six from '../../images/6t.jpg'
function News() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const animatedEls = document.querySelectorAll("[data-animation]");

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      const animation = entry.target.getAttribute("data-animation");

      if (entry.isIntersecting) {
        entry.target.classList.add("animated", `${animation}`);
      } else {
        entry.target.classList.remove("animated", `${animation}`);
      }
    });
  });
  const massiv = {
    title: ''
  }
  return (
    <div id='inform' className="container news">
      <div class="row">
        <div className='col-xl-6 col-lg-6 col-sm-12 col-12'>
          <h4 className=' fw-normal glitch'>История</h4>
          <p className='long-text hero-heading'>
            История компании, известна на данный момент рынку под именем «Evatorial», насчитывает уже более 23 лет.
            Компания крупнейшее предприятие швейной промышленности Узбекистана, специализируется на выпуске джинсовых изделии и форменного обмундирования для всех отраслей.
            Компания «EKVATORIAL GROUP» выпускает продукции в строгом соответствии с техническими условиями, предоставленными заказчиком: В зависимости от вида выполняемой операции при пошиве изделий используются различные виды швейного оборудования. Экспериментальный цех оснащен системой автоматизированного проектирования. Кроме того используется автоматизированного система вывода лекал, что позволяет обеспечивать все швейные фабрики оригиналами лекал и обеспечивать высокое и равномерное качество готовой продукции. Во все производственные процессы освоены новейшие технологии, и ежегодно расширяется с учетом динамичного развития компании.
          </p>
        </div>
        <div className='col-xl-6 col-lg-6 col-sm-12 col-12'>
          <video controls className='video1'>
  
            <source src={video1} type="video/mp4" />
          </video>
        </div>
                
                
      </div>
      <div class="row">
             
        <div className='col-xl-6 col-lg-6 col-sm-12 col-12'>
          <video controls className='video2'>
  
            <source src={video2} type="video/mp4" />
          </video>
        </div>
        <div className='col-xl-6 col-lg-6 col-sm-12 col-12'>
          <div id='assorti' class="list">
            <h4 className='fw-normal glitch'>Ассортимент</h4>
            <ul>
              <li><span onClick={handleShow}>Джинсовых брюки</span></li>
              <li><span onClick={handleShow}>Джинсовых костюмы</span></li>
              <li><span onClick={handleShow}>Рубашки</span></li>
              <li><span onClick={handleShow}>Спецодежда</span></li>
              <li><span onClick={handleShow}>Военная форма</span></li>
              <li><span onClick={handleShow}>Сарафан женская</span></li>
              <li><span onClick={handleShow}>Комбинезоны</span></li>
              <li><span onClick={handleShow}>Спортивные костюмы и брюки</span></li></ul>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> <h4 className='fw-normal text-dark text-center'>Ассортимент</h4></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="row">
              <div className="col-xl-12 col-lg-6 col-md-6 col-12 box-tab" >
                  <img src={first} className="img-fluid" />
              </div>
              <div className="col-xl-12 col-lg-6 col-md-6 col-12 box-tab">
               
                  <img src={second} className="img-fluid" />

                </div>
                                    </div>
            <div className="col-xl-12 col-lg-6 col-md-6 col-12 box-tab">
             
                  <img src={third} className="img-fluid" />

                                    </div>
            <div className="col-xl-12 col-lg-6 col-md-6 col-12 box-tab">
               
                  <img src={four} className="img-fluid" />

                                    </div>
            <div className="col-xl-12 col-lg-6 col-md-6 col-12 box-tab">
            
                  <img src={five} className="img-fluid" />

                                    </div>
            <div className="col-xl-12 col-lg-6 col-md-6 col-12 box-tab">
              
                  <img src={six} className="img-fluid" />

                    </div> 
                 

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            закрыть
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Сохранить изменения
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default News
