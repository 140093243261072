import { useState, React, Fragment } from 'react';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './Tabs.css';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, DropdownButton } from 'react-bootstrap'

function Tabs() {
   let [count, setCount] = useState(1);
   let [count1, setCount1] = useState(0);
   let [count2, setCount2] = useState(0);
   let [count3, setCount3] = useState(0);

   function incrementCount() {
      count = count + 1;
      setCount(count);
   }
   function decrementCount() {
      if (count === 0) {
         return;
      }
      count = count - 1;
      setCount(count);
   }

   function incrementCount1() {
      count1 = count1 + 1;
      setCount1(count1);
   }
   function decrementCount1() {
      if (count1 === 0) {
         return;
      }
      count1 = count1 - 1;
      setCount1(count1);
   }

   function incrementCount2() {
      count2 = count2 + 1;
      setCount2(count2);
   }
   function decrementCount2() {
      if (count2 === 0) {
         return;
      }
      count2 = count2 - 1;
      setCount2(count2);
   }

   function incrementCount3() {
      count3 = count3 + 1;
      setCount3(count3);
   }
   function decrementCount3() {
      if (count3 === 0) {
         return;
      }
      count3 = count3 - 1;
      setCount3(count3);
   }

   const allcount = count + count1 + count2 + count3

   const [selectedDate, handleDateChange] = useState(null);
   const [selectedDate1, handleDateChange1] = useState(null);

   const [flyfrom, setFlyfrom] = useState('Откуда')
   const selectFlyfrom = (v) => {
      setFlyfrom(v)
   }

   const [flyto, setFlyto] = useState('Куда')
   const selectFlyto = (v) => {
      setFlyto(v)
   }

   const [toggleState, setToggleState] = useState(1);

   const toggleTab = (index) => {
      setToggleState(index);
   };

   const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_2rtevyg', 'template_ivapbhk', form.current, {
        publicKey: 'sf_DovjZlAZWzc6Ra',
      })
      .then(
        () => {
              document.getElementById('text').textContent = "Done!!!";
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
    
   return (
      <div className="container widget">
         <div className="bloc-tabs">
            <button
               className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
               onClick={() => toggleTab(1)}
            >
            электронная почта
             
            </button>
            <button
               className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
               onClick={() => toggleTab(2)}
            >
              
              
телефон
             
            </button>
            
         </div>

         <div className="content-tabs" >
            <div
               className={toggleState === 2 ? "tb1 content  active-content" : "content"}
            >
                <div className="app-form">
                  <form ref={form} onSubmit={sendEmail}>
                     <div className="row">
                        <div className="col-lg-3 col-sm-3 d-flex flex-column">
                           <label className="fw-bold">имя</label>
                           <input type="text" name='user_name' className='py-2' />
                        </div>
                        <div className="col-lg-3 col-sm-3 d-flex flex-column">
                           <label className="fw-bold">телефон</label>
                           <input type="text" name='user_email'className='py-2' />
                     </div>
                     <div className="col-lg-4 col-sm-3 d-flex flex-column">
                           <label className="fw-bold">сообщение</label>
                           <input type="text"  name='message' className='py-2 '/>
                        </div>
                        <div className="col-lg-2">
                           <button value="Send" className='py-2 px-3 otpr mt-4' type="submit">Отправить</button>
                        </div>
                     </div>
                  </form>
                    </div>
            </div>

            <div
               className={toggleState === 1 ? "tb2 content  active-content" : "content"}
            >
                <div className="app-form">
                  <form ref={form} onSubmit={sendEmail}>
                     <div className="row">
                        <div className="col-lg-3 col-sm-3 d-flex flex-column">
                           <label className="fw-bold">имя</label>
                           <input type="text" name='user_name' />
                        </div>
                        <div className="col-lg-3 col-sm-3 d-flex flex-column">
                           <label className="fw-bold">  электронная почта</label>
                           <input type="email" name='user_email' />
                     </div>
                     <div className="col-lg-4 col-sm-3 d-flex flex-column">
                           <label className="fw-bold">сообщение</label>
                           <input type="text"  name='message'/>
                        </div>
                        <div className="col-lg-2">
                           <button value="Send"  type="submit" >Отправить</button>
                        </div>
                     </div>
                  </form>
                    </div>
            </div>

         
         </div>
      </div>
   )
}

export default Tabs;